export const MXDepositLogo = () => (
    <svg width="136px" height="37px" viewBox="0 0 136 37" version="1.1">
        <defs>
            <polygon id="path-1" points="0 36.3606836 135.688111 36.3606836 135.688111 0 0 0" />
        </defs>
        <g id="Home-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icons" transform="translate(-36.000000, -97.000000)">
                <g id="DepositLogo_AppHeader_165x45" transform="translate(36.000000, 97.000000)">
                    <g id="Group-26" transform="translate(0.000000, 0.000031)">
                        <path
                            d="M16.1620139,19.6429857 L30.6395751,11.2763159 L30.646485,27.9977201 C30.646485,27.9992905 30.6455427,28.0005469 30.6439723,28.0014891 C30.6433441,28.0018032 30.6427159,28.0021173 30.6417737,28.0021173 C30.6411455,28.0021173 16.1620139,19.6429857 16.1620139,19.6429857"
                            id="Fill-14"
                            fill="#AFDBE0"
                        />
                        <polygon
                            id="Fill-15"
                            fill="#AFDBE0"
                            points="30.6418679 27.9975945 16.1643067 19.639091 30.6418679 11.2802734"
                        />
                        <path
                            d="M16.1620139,36.3601811 C16.1607575,36.3592388 16.1598152,36.3579824 16.1598152,36.3560979 L16.1598152,19.639091 L30.6439723,27.9935113 C30.6439723,27.9935113 16.162642,36.3604952 16.1620139,36.3601811"
                            id="Fill-16"
                            fill="#84C9D2"
                        />
                        <polygon
                            id="Fill-17"
                            fill="#84C9D2"
                            points="16.1642753 19.6389339 30.6418365 27.9974374 16.1642753 36.356255"
                        />
                        <path
                            d="M16.1620139,19.6429857 C16.1607575,19.6420434 16.1598152,19.6407871 16.1598152,19.6389025 L16.1598152,2.92158152 L30.6439723,11.2763159 C30.6439723,11.2763159 16.162642,19.6432998 16.1620139,19.6429857"
                            id="Fill-18"
                            fill="#D8EDEF"
                        />
                        <polygon
                            id="Fill-19"
                            fill="#D8EDEF"
                            points="16.1642753 2.92164434 30.6418365 11.2801478 16.1642753 19.6389654"
                        />
                        <path
                            d="M1.68438984,28.0015834 L16.161951,19.6349136 L16.168861,36.3563178 C16.168861,36.3578882 16.1679187,36.3591446 16.1666624,36.3600868 C16.1657201,36.3604009 16.1650919,36.360715 16.1644637,36.360715 C16.1635215,36.360715 1.68438984,28.0015834 1.68438984,28.0015834"
                            id="Fill-20"
                            fill="#50B7C3"
                        />
                        <polygon
                            id="Fill-21"
                            fill="#50B7C3"
                            points="16.1642753 36.356255 1.68671409 27.9977515 16.1642753 19.6389339"
                        />
                        <path
                            d="M1.68438984,28.0015834 C1.68313349,28.0006411 1.68219122,27.9993848 1.68219122,27.9975002 L1.68219122,11.2801792 L16.1666624,19.6349136 C16.1666624,19.6349136 1.68501801,28.0018975 1.68438984,28.0015834"
                            id="Fill-22"
                            fill="#00A6B7"
                        />
                        <polygon
                            id="Fill-23"
                            fill="#00A6B7"
                            points="1.68668268 11.2803048 16.1642439 19.6388083 1.68668268 27.9976259"
                        />
                        <path
                            d="M0,8.36666975 L14.4775612,0 L14.4844711,16.7214042 C14.4844711,16.7229746 14.4835289,16.724545 14.4819584,16.7254873 C14.4813303,16.7258014 14.4807021,16.7258014 14.4797598,16.7258014 C14.4791316,16.7258014 0,8.36666975 0,8.36666975"
                            id="Fill-24"
                            fill="#F36C3E"
                        />
                        <polygon
                            id="Fill-25"
                            fill="#F36C3E"
                            points="14.4798226 16.7214042 0.00226143187 8.36290069 14.4798226 0.00408314088"
                        />
                    </g>
                    <polygon
                        id="Fill-1"
                        fill="#FFFFFE"
                        points="59.5840813 11.3850531 71.5059104 11.3850531 71.5059104 13.0817552 61.4453654 13.0817552 61.4453654 18.7139769 70.4455501 18.7139769 70.4455501 20.410679 61.4453654 20.410679 61.4453654 26.1842402 71.6236933 26.1842402 71.6236933 27.8809423 59.5840813 27.8809423"
                    />
                    <path
                        d="M79.6594402,20.36366 C82.3926319,20.36366 84.1832462,18.9025238 84.1832462,16.734376 L84.1832462,16.6872628 C84.1832462,14.3306624 82.4158744,13.105406 79.7533524,13.105406 L75.5832092,13.105406 L75.5832092,20.36366 L79.6594402,20.36366 Z M73.7219252,11.3851473 L79.8946919,11.3851473 C83.61726,11.3851473 86.0677727,13.3642143 86.0677727,16.6165931 L86.0677727,16.6637062 C86.0677727,20.2223206 83.0993293,22.0603621 79.5887704,22.0603621 L75.5832092,22.0603621 L75.5832092,27.8810365 L73.7219252,27.8810365 L73.7219252,11.3851473 Z"
                        id="Fill-2"
                        fill="#FFFFFE"
                    />
                    <path
                        d="M102.249354,19.6802365 L102.249354,19.6328092 C102.249354,15.8860564 99.5161626,12.8224443 95.7467954,12.8224443 C91.9768,12.8224443 89.2910356,15.8389432 89.2910356,19.5860102 L89.2910356,19.6328092 C89.2910356,23.3801903 92.0239132,26.4434882 95.7939085,26.4434882 C99.5632758,26.4434882 102.249354,23.4269894 102.249354,19.6802365 M87.3587677,19.6802365 L87.3587677,19.6328092 C87.3587677,15.0848185 90.7754143,11.1018716 95.7939085,11.1018716 C100.812089,11.1018716 104.181308,15.0377053 104.181308,19.5860102 L104.181308,19.6328092 C104.181308,24.1811141 100.76529,28.1637469 95.7467954,28.1637469 C90.7283012,28.1637469 87.3587677,24.2282273 87.3587677,19.6802365"
                        id="Fill-4"
                        fill="#FFFFFE"
                    />
                    <path
                        d="M104.79535,25.4773857 L105.949936,24.1104758 C107.669881,25.6658383 109.319156,26.4435196 111.604772,26.4435196 C113.819405,26.4435196 115.279913,25.2653764 115.279913,23.63903 L115.279913,23.5922309 C115.279913,22.0601109 114.455433,21.1885173 110.991987,20.4576351 C107.198749,19.6328406 105.455248,18.4075843 105.455248,15.6976351 L105.455248,15.6502079 C105.455248,13.0583557 107.740551,11.1493303 110.874204,11.1493303 C113.27729,11.1493303 114.997234,11.8327852 116.670066,13.1758245 L115.586149,14.6134042 C114.054657,13.3642771 112.523479,12.8224758 110.826777,12.8224758 C108.682814,12.8224758 107.316532,14.0006189 107.316532,15.4853118 L107.316532,15.532739 C107.316532,17.0877875 108.164569,17.9600092 111.792911,18.7373764 C115.46868,19.5389284 117.164754,20.8819677 117.164754,23.4034642 L117.164754,23.4508915 C117.164754,26.2786236 114.808782,28.1166651 111.534103,28.1166651 C108.918694,28.1166651 106.774731,27.2447575 104.79535,25.4773857"
                        id="Fill-6"
                        fill="#FFFFFE"
                    />
                    <g id="Clip-9" />
                    <polygon
                        id="Fill-8"
                        fill="#FFFFFE"
                        mask="url(#mask-2)"
                        points="119.003769 27.8809423 120.865367 27.8809423 120.865367 11.3850531 119.003769 11.3850531"
                    />
                    <polygon
                        id="Fill-10"
                        fill="#FFFFFE"
                        mask="url(#mask-2)"
                        points="128.266751 13.1053118 122.730012 13.1053118 122.730012 11.3850531 135.688017 11.3850531 135.688017 13.1053118 130.151278 13.1053118 130.151278 27.8809423 128.266751 27.8809423"
                    />
                    <path
                        d="M49.003721,11.3850531 L43.2785293,11.3850531 L43.2785293,15.131806 L45.1398134,15.131806 L45.1398134,13.1053118 L49.003721,13.1053118 C53.1738642,13.1053118 55.8360721,15.9804711 55.8360721,19.6329977 L55.8360721,19.6801109 C55.8360721,23.3326374 53.1738642,26.1606836 49.003721,26.1606836 L45.1398134,26.1606836 L45.1398134,24.150836 L43.2785293,24.150836 L43.2785293,27.8809423 L49.003721,27.8809423 C54.1871113,27.8809423 57.7680259,24.2755289 57.7680259,19.6329977 L57.7680259,19.5858845 C57.7680259,14.9436674 54.1871113,11.3850531 49.003721,11.3850531"
                        id="Fill-11"
                        fill="#FFFFFE"
                        mask="url(#mask-2)"
                    />
                    <polygon
                        id="Fill-12"
                        fill="#F36C3E"
                        mask="url(#mask-2)"
                        points="37.9021949 16.6429136 38.3309247 16.6429136 40.790546 20.2665441 43.2498531 16.6429136 43.6785829 16.6429136 43.6785829 22.7698236 43.2237838 22.7698236 43.2237838 17.4482346 40.7993404 20.9754402 40.7641626 20.9754402 38.3397192 17.4482346 38.3397192 22.7698236 37.9021949 22.7698236"
                    />
                    <polygon
                        id="Fill-13"
                        fill="#F36C3E"
                        mask="url(#mask-2)"
                        points="47.0303704 19.6452157 44.684763 16.6428508 45.2275067 16.6428508 47.3193312 19.3477746 49.4023612 16.6428508 49.9363104 16.6428508 47.5819085 19.6452157 50.0239409 22.7697607 49.4811972 22.7697607 47.3020564 19.9426568 45.1313958 22.7697607 44.5974467 22.7697607"
                    />
                </g>
            </g>
        </g>
    </svg>
);
